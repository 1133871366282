import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Container, Modal, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
// import { Sidebar } from 'tvs-unified-lib';
import { useLocation } from 'react-router-dom';

import TimeIdle from '../components/elements/IdleTime';
import ZendeskChatWidget from '../components/Zendesk/ChatWidget';
import Header from '../components/layouts/Header';
import Sidebar from '../components/layouts/Sidebar';
import useScreenSize from '../utils/screenSize';
import { IDLE_TIMEOUT, Localization } from '../constant';

type DashboardTemplateProps = {
	children?: React.ReactNode;
};

const DashboardTemplate: FC<DashboardTemplateProps> = ({ children }) => {
	const { t } = useTranslation();
	const size = useScreenSize();
	const [isTimeout, setIsTimeout] = useState(false);
	const [showSidebar, setShowSidebar] = useState(false);
	const [triggerChat, setTriggerChat] = useState<boolean>(false);
	const [sidebarChatWidgetLoading, setSidebarChatWidgetLoading] =
		useState<boolean>(true);
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		if (size.screen === 'l' || size.screen === 'xl') {
			setShowSidebar(true);
		} else {
			setShowSidebar(false);
		}
	}, [size]);

	// useEffect(() => {
	//   const timer = new TimeIdle({
	//     timeout: IDLE_TIMEOUT,
	//     onTimeout: () => {
	//       setIsTimeout(true);
	//     },
	//     onExpire: () => {},
	//   });

	//   return () => {
	//     timer.cleanUp();
	//   };
	// }, []);

	// const handleTimeout = () => {
	//   setIsTimeout(false);
	// };

	return (
		<Main>
			{/* <Modal isOpen={isTimeout} centered>
        <ModalBody>
          <div>{t("Your session is expired. Please login again")}</div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleTimeout()} color="primary border-0">
            {t("Ok")}
          </Button>
        </ModalFooter>
      </Modal> */}
			<Header
				onClickMenu={() => {
					window.scrollTo(0, 0);
					setShowSidebar(!showSidebar);
				}}
			/>
			<Container style={{ paddingLeft: 0, paddingRight: 0 }} fluid>
				<div className='d-flex h-100'>
					{showSidebar && (
						<Sidebar
							size={size}
							setShowSidebar={setShowSidebar}
							supportChatLoading={sidebarChatWidgetLoading}
							onSupportChatClick={() => {
								setTriggerChat(!triggerChat);
							}}
						/>
					)}
					<Content style={{ paddingTop: '4.5%' }}>
						<div>{children}</div>
					</Content>
				</div>
				<ZendeskChatWidget
					hideOnLoad={true}
					showChat={triggerChat}
					onFinishScriptLoad={() => setSidebarChatWidgetLoading(false)}
					onCloseChatWidget={() => setTriggerChat(false)}
				/>
			</Container>
		</Main>
	);
};

const Main = styled.div`
	background-color: #f5f5f5;
	display: flex;
	height: 100%;
	width: 100%;
	min-height: 100vh;
	overflow-x: hidden;
`;

const Content = styled.div`
	flex: 1;
	margin-top: 5px;
	margin-left: 250px;
	@media (max-width: 990px) {
		margin-left: 0;
	}
	@media (max-width: 414px) {
		padding: 0;
	}
`;

export default DashboardTemplate;
