export const Localization = {
	logoFilename: 'Logo TVS.svg',
	logoGrayFilename: 'Logo TVSGray.svg',
	logoMotoraceFilename: 'Logo motorace.png',
	logoWheeltekFilename: 'Logo wheeltek.png',
	logoNewnemarFilename: 'Logo newnemar.png',
	clientName: 'TVSC',
	navbarBackgroundColor: '#5665D2',
	PRIVACY_POLICY_URL: 'https://www.tvsd.ai/en/privacy-policy',
	TERMS_OF_USE_URL: 'https://www.tvsd.ai/en/terms-of-use',
	COOKIE_POLICY_URL: 'https://www.tvsd.ai/en/cookie-policy',
	eulaTextUrl:
		'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/eula/EULA_LOS_webapp.txt',
	eulaVersionUrl:
		'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/eula/EULA_LOS_webapp.json',
	privacyStatementUrl:
		'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.txt',
	privacyStatementVersion:
		'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.json',
	isForMotorace: process.env.REACT_APP_CLIENT === 'MOTORACE',
	isForWheeltek: process.env.REACT_APP_CLIENT === 'WHEELTEK',
	isForNewNemar: process.env.REACT_APP_CLIENT === 'NEW_NEMAR',
	isForTraining: process.env.REACT_APP_CLIENT === 'TRAINING',
	isForDemo: process.env.REACT_APP_CLIENT === 'DEMO',
	isProduction: process.env.REACT_APP_ENV === 'PROD',
	isUsingMotoraceConfig: process.env.REACT_APP_ENV_CONFIG === 'MOTORACE',
	isUsingWheeltekConfig: process.env.REACT_APP_ENV_CONFIG === 'WHEELTEK',
	isUsingNewNemarConfig: process.env.REACT_APP_ENV_CONFIG === 'NEW_NEMAR',
	isUsingTrainingConfig: process.env.REACT_APP_ENV_CONFIG === 'TRAINING',
	isUsingDemoConfig: process.env.REACT_APP_ENV_CONFIG === 'DEMO',
	zendeskKey: process.env.REACT_APP_ZENDESK_KEY,
};
