import { FC } from "react";
import DashboardTemplate from "../themes/DashboardTemplate";
import MobileTemplate from "themes/mobileTemplate";

interface IRouterPattern {
  path: string;
  noAuth?: boolean;
  pageComponent: string;
  isExact?: boolean;
  layout?: FC;
}

const loginRoute: IRouterPattern[] = [
  {
    path: "/login",
    noAuth: true,
    pageComponent: "Login",
  },
  {
    path: "/verify",
    noAuth: true,
    pageComponent: "Verify",
    isExact: true,
  },
];

const clientRoute: IRouterPattern[] = [
  {
    path: "/supportingDocuments/:customerName/:applicationNumber/:salesAgent/:loanApplicationId/:id",
    noAuth: true,
    layout: MobileTemplate,
    pageComponent: "LoanApplicationMobile/Index",
  },
];

const dashboardRoute: IRouterPattern[] = [
  {
    path: "/",
    pageComponent: "Loans/loanPage",
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: "/dashboard",
    pageComponent: "Dashboard/index",
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: "/loans",
    pageComponent: "Loans/loanPage",
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: "/task",
    pageComponent: "Tasks/task",
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: "/loans/new-application/:loanId/:id/:stepId",
    pageComponent: "LoanApplication/action",
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: "/loans/new-application/:loanId/:id",
    pageComponent: "LoanApplication/action",
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: "/loans/new-application/new/:id",
    pageComponent: "LoanApplication/action",
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: "/loan-list",
    pageComponent: "LoanListing/index",
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: "/customer-profile/:id",
    pageComponent: "CustomerProfile/index",
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: "/loan-details/:id/:id",
    pageComponent: "LoanDetails/index",
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: "/new-customer",
    pageComponent: "CustomerCreation/index",
    layout: DashboardTemplate,
    isExact: true,
  },
];

const getRoutes = () => {
  return [...loginRoute, ...dashboardRoute, ...clientRoute];
};

export default getRoutes;
